import React, { Component } from 'react';
import {
  Button,
  Form,
  Grid,
  Input,
  Header,
  Message,
  Segment
} from 'semantic-ui-react';
import customerService from "../services/customer"
import practitioner from '../services/practitioner';
import messages from '../config/messages';
import { convertResponseError } from '../helpers/util';
import { logout } from '../helpers/auth';

class LoginResetPassword extends Component {

  state = {
    remoteSuccess: false,
    remoteError: null,
    isLoading: false,
    password: "",
    pin: "",
    email: "",
    role: "",
    validationErrors: {}
  }

  componentDidMount = () => {
    const {
      match: {
        params: { userId },
      },
      location: { search }
    } = this.props;

    this.verifyUserLink(userId, search);
  };

  /**
   * On change input
   * 
   * @param {Event} event 
   * @param {String, String} param1 
   */
  onChangeInput = (event, { name, value }) => {
    this.setState({ [name]: value })
  }

  /**
  * Verify User Email
  */
  verifyUserLink = (userId, code) => {

    this.setState({ remoteError: null, isLoading: true });

    customerService
      .verifyUserLink(userId, code)
      .then((response) => {
        this.setState({ isLoading: false });
        if (!response) {
          this.setState({
            remoteError: { message: messages["no-responce-from-server"] },
          });
          return;
        }
        const { email, role } = response.data;

        this.setState({ email, role });

        logout();
      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
        logout();
      });
  };

  /**
   * Handle Submit
   */
  handleSubmit = async () => {

    const { pin, password, role } = this.state;

    const fields = {
      pin, password
    }
    if (role !== messages["role-customer"]) {
      delete fields.password;
    }
    const isValid = role === messages["role-customer"] ? await customerService.resetPasswordSchema.isValid(fields) : await customerService.resetPasswordCustomerSchema.isValid(fields);

    if (!isValid) {
      this.validateAll(fields);
      return;
    }

    this.setPinPassowrd(fields);
  }

  /**
* Validate All
*
* @param {Object} fields
* @param {Sting} parentPath
* @param {Object} root
*/
  validateAll(fields, parentPath, root) {
    root = root ? root : fields;
    parentPath = parentPath ? parentPath : "";

    for (const field of Object.keys(fields)) {
      if (typeof fields[field] === "object" && fields[field] != null) {
        this.validateAll(fields[field], parentPath + field + ".", root);
      } else {
        this.validateOne(parentPath + field, root);
      }
    }
  }

  /**
   * Validate One
   *
   * @param {Object} field
   * @param {Object} fields
   */
  validateOne(field, fields) {
    const { role } = this.state;
    const checkValidation = role === messages["role-customer"] ? customerService.resetPasswordSchema : customerService.resetPasswordCustomerSchema;
    checkValidation.validateAt(field, fields).catch((error) => {
      this.setState((state) => ({
        validationErrors: {
          ...state.validationErrors,
          [field]: error.errors[0],
        },
      }));
    });
  }

  /**
   * Set pin password
   * 
   * @param {Object} newPassword 
   */
  setPinPassowrd = newPassword => {
    const {
      match: {
        params: { userId },
      },
      location: { search }
    } = this.props;

    this.setState({ isLoading: true });

    practitioner.resetPassword(userId, newPassword, search)
      .then(response => {
        this.setState({ isLoading: false, remoteSuccess: true });
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          remoteError: convertResponseError(error)
        });
      })
  }

  /**
   *  Render View
   */
  render() {
    const {
      isLoading,
      remoteError,
      remoteSuccess,
      email,
      password,
      pin,
      role,
      validationErrors,
    } = this.state;

    const getResetPasswordString = () => {
      if (role && role === messages["role-customer"]) return "Reset Passwords";

      return "Reset Password";
    }

    return (
      <div className="container-center-wrap">
        {remoteError ? (
          <Message error>
            <Message.Header>Error {remoteError.status}</Message.Header>
            {remoteError.status === 400 && remoteError.validationErrors ? (
              <ul className="list">
                {remoteError.validationErrors.map((item, key) => (
                  <li key={key} className="content">
                    {item.defaultMessage}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{remoteError.message}</p>
            )}
          </Message>
        ) : (
          <Segment.Group>
            <Segment>
              <Form autocomplete="off"
                loading={isLoading}
              >
                <Header as='h3'>Reset Passwords</Header>
                <Form.Field
                  className="mt-10"
                >
                  <label>Email *</label>
                  <Input
                    type="text"
                    placeholder="Email Address"
                    name="email"
                    disabled
                    value={email}
                  />

                </Form.Field>
                {role && role === messages["role-customer"] && (
                  <Form.Field
                    className="mt-10"
                    error={Boolean(validationErrors["password"])}
                  >
                    <label>Password *</label>
                    <Input
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={password}
                      onChange={this.onChangeInput}
                    />
                    <p className="mt-5">Please set a password. You will use this password to login to the AXIT app and the Dashboard.</p>
                  </Form.Field>
                )}
                <Form.Field
                  className="mt-10"
                  error={Boolean(validationErrors["pin"])}
                >
                  <label>Pin *</label>
                  <Input
                    type="password"
                    placeholder="Pin"
                    name="pin"
                    value={pin}
                    onChange={this.onChangeInput}
                  />
                  <p className="mt-5">Please set a PIN of 4 to 8 digits. You will use this PIN to login as a practitioner on the AXIT app.</p>
                </Form.Field>

                {remoteSuccess && (
                  <Message
                    positive
                    content={messages["password-change"]}
                  >
                  </Message>
                )}

                <Button
                  primary
                  onClick={this.setPinPassword}
                >
                  {getResetPasswordString()}
                </Button>
              </Form>
            </Segment>
          </Segment.Group>
        )}
      </div>
    );
  }
}

export default LoginResetPassword;