/**
 * Import the Dependencies
 */
import React, { Component } from "react";
import {
  Segment,
  Form,
  Header,
  Input,
  Button,
  Label,
  Message,
  Grid
} from "semantic-ui-react";
import customerService from "../../services/customer";
import practitioner from "../../services/practitioner";
import messages from "../../config/messages";
import { convertResponseError } from "../../helpers/util";
import { logout } from "../../helpers/auth";

class AcceptInvitation extends Component {
  state = {
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    pin: '',
    validationErrors: {},
    isLoading: false,
    remoteSuccess: false,
    remoteError: null,
    invitationError: null
  };

  componentDidMount = () => {
    const {
      match: {
        params: { userId },
      },
      location: { search }
    } = this.props;

    this.verifyUserLink(userId, search);
  };

  /**
   * On change input
   * 
   * @param {Event} evt 
   * @param {String, string} param1 
   */
  onChangeInput = (evt, { name, value }) => {
    this.setState({ [name]: value });
  }


  /**
   * Validate All
   *
   * @param {Object} fields
   * @param {Sting} parentPath
   * @param {Object} root
   */
  validateAll(fields, parentPath, root) {
    root = root ? root : fields;
    parentPath = parentPath ? parentPath : '';
    for (const field of Object.keys(fields)) {
      if (typeof fields[field] === "object" && fields[field] != null) {
        this.validateAll(fields[field], parentPath + field + '.', root);
      } else {
        this.validateOne(parentPath + field, root);
      }
    }
  }


  /**
   * Validate One
   *
   * @param {Object} field
   * @param {Object} fields
   */
  validateOne(field, fields) {
    const schema = practitioner.acceptInvitationSchema;
    schema.validateAt(field, fields)
      .catch(error => {
        this.setState(state => ({
          validationErrors: {
            ...state.validationErrors,
            [field]: error.errors[0]
          }
        }));
      });
  }

  /**
   * Handle submit
   * 
   * @param {Event} event 
   * @returns 
   */
  handleSubmit = async event => {
    event.preventDefault();
    const {
      phone,
      email,
      firstName,
      lastName,
      pin }
      = this.state;

    const fields = {
      email,
      firstName,
      lastName,
      pin,
      phone
    }

    this.setState({ validationErrors: {}, remoteError: null })

    const isValid = await practitioner.acceptInvitationSchema.isValid(fields);

    if (!isValid) {
      this.validateAll(fields);
      return;
    }
    this.acceptInvitation(fields);
  }

  /**
   * Accept invitation
   * 
   * @param {Object} data 
   */
  acceptInvitation = data => {
    this.setState({
      isLoading: true,
      remoteError: null,
      invitationError: null,
    })

    const fields = {
      pin: data.pin,
      contactDetail: {
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
      }
    }

    const {
      match: {
        params: { userId },
      },
      location: { search }
    } = this.props;

    console.log(this.props)

    practitioner.resetPassword(userId, fields, search)
      .then(response => {
        this.setState({ isLoading: false })
        if (!response) {
          this.setState({
            invitationError: { message: messages["no-responce-from-server"] },
          });
          return;
        }
        this.setState({ remoteSuccess: true })
      })
      .catch((error) => {
        this.setState({
          invitationError: convertResponseError(error),
          isLoading: false,
        });
      });
  }

  /**
   * Verify User Email
   */
  verifyUserLink = (userId, code) => {

    this.setState({ remoteError: null, isLoading: true });

    customerService
      .verifyUserLink(userId, code)
      .then((response) => {
        this.setState({ isLoading: false });

        if (!response) {
          this.setState({
            remoteError: { message: messages["no-responce-from-server"] },
          });

          return;
        }
        if (response.data && response.data.email) {
          const email = response.data.email
          this.setState({ email })
        }
        if (response.data && response.data.contactDetail) {
          const { contactDetail } = response.data;
          const { phone, firstName, lastName } = contactDetail;
          this.setState({
            phone,
            firstName,
            lastName
          })
        }
        logout();
      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
        logout();
      });
  };

  /**
   * Render View
   */
  render() {
    const {
      remoteError,
      remoteSuccess,
      invitationError,
      isLoading,
      validationErrors,
      email,
      phone,
      firstName,
      lastName,
      pin
    } = this.state;

    return (
      <div className="container-center-wrap">
        {remoteError ? (
          <Message error>
            <Message.Header>Error {remoteError.status}</Message.Header>
            {remoteError.status === 400 && remoteError.validationErrors ? (
              <ul className="list">
                {remoteError.validationErrors.map((item, key) => (
                  <li key={key} className="content">
                    {item.defaultMessage}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{remoteError.message}</p>
            )}
          </Message>
        ) : (
          <>
            {!remoteSuccess ? (
              <>
                <Form loading={isLoading}>
                  <Segment.Group>
                    <Segment>
                      <Header as='h3'>You have been invited to join Business Name as a practitioner</Header>
                      <Form.Field
                        error={Boolean(validationErrors["email"])}
                      >
                        <label>Email Address *</label>
                        <Input
                          placeholder='Email Address'
                          value={email}
                          type='email'
                          name="email"
                          disabled={true}
                        />
                      </Form.Field>
                      <Form.Field
                        error={Boolean(validationErrors["pin"])}
                      >
                        <label>PIN *</label>
                        <Input
                          type='text'
                          placeholder='PIN'
                          value={pin}
                          name="pin"
                          onChange={this.onChangeInput}
                        />
                        {Boolean(validationErrors["pin"]) && (
                          <Label basic color="red" pointing>
                            {validationErrors["pin"]}
                          </Label>
                        )}
                        <p className="mt-5">Please set a PI?N of 4 to 8 digits. You will use this PIN to login to the AXIT app.</p>
                      </Form.Field>

                      <Form.Group widths='equal'>
                        <Form.Field
                          error={Boolean(validationErrors["firstName"])}
                        >
                          <label>First Name *</label>
                          <Input
                            placeholder='First Name'
                            value={firstName}
                            name="firstName"
                            onChange={this.onChangeInput}
                          />
                          {Boolean(validationErrors["firstName"]) && (
                            <Label basic color="red" pointing>
                              {validationErrors["firstName"]}
                            </Label>
                          )}
                        </Form.Field>
                        <Form.Field
                          error={Boolean(validationErrors["lastName"])}
                        >
                          <label>Last Name *</label>
                          <Input
                            placeholder='Last Name'
                            value={lastName}
                            name="lastName"
                            onChange={this.onChangeInput}
                          />
                          {Boolean(validationErrors["lastName"]) && (
                            <Label basic color="red" pointing>
                              {validationErrors["lastName"]}
                            </Label>
                          )}
                        </Form.Field>
                      </Form.Group>
                      <Form.Field
                        error={Boolean(validationErrors["phone"])}
                      >
                        <label>Phone</label>
                        <Input
                          type='text'
                          name="phone"
                          placeholder='Phone Number'
                          value={phone}
                          onChange={this.onChangeInput}
                        />
                        {Boolean(validationErrors["phone"]) && (
                          <Label basic color="red" pointing>
                            {validationErrors["phone"]}
                          </Label>
                        )}
                      </Form.Field>
                      <Button
                        primary
                        content="Accept Invitation"
                        onClick={this.handleSubmit}
                      />
                    </Segment>
                  </Segment.Group>
                </Form>
                {invitationError && (
                  <Message error>
                    <Message.Header>Error {invitationError.status}</Message.Header>
                    {invitationError.status === 400 && invitationError.validationErrors ? (
                      <ul className="list">
                        {invitationError.validationErrors.map((item, key) => (
                          <li key={key} className="content">
                            {item.defaultMessage}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>{invitationError.message}</p>
                    )}
                  </Message>
                )}
              </>
            ) :
              (<Segment className="fake-segment">
                <Grid
                  textAlign="center"
                  verticalAlign="middle"
                  className="full-height-container"
                >
                  <Grid.Column>
                    <Grid celled textAlign="center">
                      <Grid>
                        <Grid.Row />
                        <Grid.Row>
                          <Grid.Column>
                            <Header as="h3">Congratulations</Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                        </Grid.Row>
                        <Grid.Row>
                        </Grid.Row>
                        <Grid.Row>
                        </Grid.Row>
                        <Grid.Row>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <Header as="h4">Restart your AxIT app, you will see yourself on the Practitioner Login screen</Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row />
                        <Grid.Row>
                        </Grid.Row>
                        <Grid.Row>
                        </Grid.Row>
                      </Grid>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Segment>
              )}
          </>
        )
        }
      </div>
    );
  }
}

/**
 * Export the Component
 */
export default AcceptInvitation;
