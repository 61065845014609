import * as Yup from 'yup';

import { sha256 } from 'js-sha256';
import request from '../helpers/request';

const addSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Required')
    .email('Invalid email'),
  firstName: Yup.string()
    .max(50, 'Too long'),
  lastName: Yup.string()
    .max(50, 'Too long'),
  role: Yup.string()
    .nullable()
});

const acceptInvitationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Required')
    .email('Invalid email'),
  firstName: Yup.string()
    .required("Required")
    .min(1, "Too short")
    .max(50, 'Too long'),
  lastName: Yup.string()
    .required("Required")
    .min(1, "Too short")
    .max(50, 'Too long'),
  pin: Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, 'PIN can only contain numbers')
    .min(4, 'Minimum 4')
    .max(8, 'Maximum 8'),
  phone: Yup.string()
    .trim()
    .required("Required")
    .min(8, "Minimum 8")
    .max(12, "Maximum 12"),
})

const updateSchema = addSchema.shape({
  contactEmail: Yup.string()
    .trim()
    .required('Required')
    .email('Invalid email'),
  phone: Yup.string()
    .trim()
    .required("Required")
    .min(8, "Minimum 8")
    .max(12, "Maximum 12"),
  firstName: Yup.string()
    .required("Required")
    .min(1, "Too short")
    .max(50, 'Too long'),
  lastName: Yup.string()
    .required("Required")
    .min(1, "Too short")
    .max(50, 'Too long'),
  password: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, 'PIN can only contain numbers')
    .min(6, 'Too short')
    .max(16, 'Too long'),
  repeatPassword: Yup.mixed()
    .when('password', {
      is: (value) => {
        return typeof value !== 'undefined' && value !== null
      },
      then: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('password'), null], "PINs do not match"),
      otherwise: Yup.string().nullable()
    }),
  currentPassword: Yup.mixed()
    .when('password', {
      is: (value) => {
        return typeof value !== 'undefined' && value !== null
      },
      then: Yup.string()
        .required('Required'),
      otherwise: Yup.string().nullable()
    })
});

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, 'PIN can only contain numbers')
    .min(6, 'Too short')
    .max(16, 'Too long'),
});


export default {
  addSchema,
  updateSchema,
  resetPasswordSchema,
  acceptInvitationSchema,

  getAllPractitioners: function (customerId) {
    return request.get(`/customers/${customerId}/practitioners`);
  },

  getPractitionerById: function (id) {
    return request.get(`/practitioners/${id}`);
  },

  addPractitioner: function (customerId, practitioner) {
    return request.post(`/customers/${customerId}/practitioners`, practitioner);
  },

  updatePractitioner: function (id, user) {
    const practitioner = {
      id,
      user
    }
    return request.put(`/practitioners/${id}`, practitioner);
  },

  updatePractitionerProfile: function (id, practitioner, customerId) {
    if (practitioner.currentPassword != null) {
      practitioner.currentPassword = sha256(practitioner.currentPassword + customerId);
    }
    if (practitioner.password != null) {
      practitioner.password = sha256(practitioner.password + customerId);
    }
    delete practitioner.repeatPassword;
    return request.put(`/practitioners/${id}/profile`, practitioner);
  },

  patchPractitioner: function (practitionerId, patch) {
    return request.patch(`/practitioners/${practitionerId}`, patch);
  },

  verifyPractitionerResetPasswordRequest: function (practitionerId, code) {
    return request.get(`/practitioners/${practitionerId}/reset-password-request?code=${code}`);
  },

  resendInvitationEmail: function (practitionerId) {
    return request.post(`/practitioners/${practitionerId}/invitation-email`);
  },

  sendResetPasswordEmail: function (email) {
    return request.post('/send-reset-email/users/email/' + email);
  },
  
  acceptInvitation: function (userId, data, code) {
    return request.put(`/users/${userId}/reset${code}`, data);
  },

  sendResetPasswordEmail: function (email) {
    return request.post(`/send-reset-email/users/email/${email}`);
  },

  resetPassword: function (userId, password, code) {
    return request.put(`/users/${userId}/reset${code}`, password);
  },
}